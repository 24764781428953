<script setup>
const nuxtApp = useNuxtApp()

const staticURLAdimg = useRuntimeConfig().public.staticURLAdimg
const staticURLImg = useRuntimeConfig().public.staticURLImg

const props = defineProps({
    spot: {
        type: String,
        default: null,
    },
    page: {
        type: String,
        default: null,
    },
    status: {
        type: String,
        default: 'Paid',
    },
    position: {
        type: String,
        default: null,
    },
})

const { data, status, error } = await useLazyAsyncData(
    `${props.spot}-${props.page}-${props.position}`,
    () => $fetch(`/api/campaign/bookings?spot=${props.spot}&page=${props.page}&status=${props.status}`),
    {
        transform: (data) => {
            return {
                centerData: data.bookings.edges.map((edge) => edge.node),
                totalCount: data.bookings.totalCount,
                fetchedAt: new Date(Date.now()),
            }
        },
        getCachedData(key) {
            const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

            if (!data) {
                return
            }

            const expirationDate = new Date(data.fetchedAt)
            expirationDate.setTime(expirationDate.getTime() + 60 * 60000) // cache set to 1 hour
            const isExpired = expirationDate.getTime() < Date.now()
            if (isExpired) {
                return
            }

            return data
        },
    }
)

if (error.value) {
    throw createError({
        statusCode: 500,
        message: error.value,
        fatal: false,
    })
}

// Calculate column size per total number of ads
const columns = computed(() => {
    let colSize = 12
    switch (data.value.totalCount) {
        case 1:
            colSize = 12
            break
        case 2:
            colSize = 6
            break
        case 3:
            colSize = 4
            break
        case 4:
            colSize = 3
            break
        default:
            break
    }
    return colSize
})

function imgSources(v) {
    return JSON.parse(v) ? JSON.parse(v).imgSources : null
}
</script>

<template>
    <v-row id="adbody" class="justify-center">
        <v-col v-if="status === 'pending' || status === 'idle'"><app-loader /></v-col>
        <v-col v-else v-for="item in data.centerData" :key="item.campaign.title" cols="12" :md="columns">
            <v-img
                id="cardImg"
                :src="item.campaign.isInternal ? staticURLImg + item.campaign.img : staticURLAdimg + item.campaign.img"
                class="rounded st-box-shadow"
                :alt="item.campaign.title"
            >
                <template #sources>
                    <source
                        v-for="imgSrc in imgSources(item.campaign.content)"
                        :key="imgSrc.id"
                        :media="imgSrc.media"
                        :srcset="staticURLAdimg + imgSrc.img"
                    />
                </template>
                <nuxt-link
                    :id="item.campaign.urlId"
                    :data-adspot="`${item.spot.name}-${item.spot.page.name}`"
                    :data-adbrand="item.brand.name"
                    :data-adtitle="item.campaign.title"
                    :to="item.campaign.url"
                    :target="item.campaign.isInternal ? undefined : `_blank`"
                    :class="`text-decoration-none text-grey-darken-4 ${item.campaign.gtag}`"
                    :aria-label="item.campaign.title"
                    rel="noopener"
                >
                    <v-container class="fill-height align-start"> </v-container>
                </nuxt-link>
                <img
                    v-if="item.campaign.trkngPxl"
                    :style="item.campaign.trkngPxlStyle"
                    :src="item.campaign.trkngPxl"
                    :width="item.campaign.trkngPxlWidth"
                    :height="item.campaign.trkngPxlHeight"
                    :alt="item.campaign.title"
                    border="0"
                />
            </v-img>
        </v-col>
    </v-row>
</template>

<style scoped></style>
